<template>
    <div>
        <div class="pagination-info"
             v-if="!disablePagination"
        >{{ showingFrom }}-{{ showingTill }} {{ $t('common.label.from') }}
            {{ inputVal.tableOptions.totalRows }}
        </div>
        <div class="clearfix"></div>
        <div :class="disablePagination ? 'form-inline mt-3' : 'form-inline'" style="float:right">
            <template v-if="!disablePagination">
                <b-dropdown v-if="value.savedFilters" class="mr-2 mb-3" right variant="light">
                    <template #button-content>
                        <font-awesome-icon icon="filter"/>
                    </template>

                    <b-dropdown-item variant="success"
                                     @click.prevent="$root.$children[0].openModal('save-filter-modal', {
                                         filters: value.filter,
                                         tableOptions: value.tableOptions,
                                         tableUniqKey,
                                         tableFormat,
                                         table,
                                         baseParams:value.ctxBaseParams(),
                                         additionalParams:value.ctxAdditionalParams()
                                         },null, {width: '800px'})"
                    >
                        <font-awesome-icon class="mr-2" icon="save"/>

                        {{ $t('common.button.save') }}
                    </b-dropdown-item>

                    <b-dropdown-divider v-if="value.savedFilters.length"></b-dropdown-divider>

                    <b-dropdown-item v-for="savedFilter in value.savedFilters"
                                     :key="savedFilter.id"
                                     @click.prevent="filterById(savedFilter.id)"
                                     style="padding-right: 45px"
                    >
                        {{ savedFilter.name }}
                        <font-awesome-icon size="sm"
                                           icon="save"
                                           class="text-success mr-1"
                                           style="position: absolute;right:25px;"
                                           @click.stop="updateSavedFilter(savedFilter.id)"
                        />
                        <font-awesome-icon size="sm"
                                           icon="trash"
                                           class="text-danger mr-1"
                                           style="position: absolute;right:5px;"
                                           @click.stop="deleteSavedFilter(savedFilter.id)"
                        />
                    </b-dropdown-item>
                </b-dropdown>

                <b-pagination
                    align="end"
                    v-model="inputVal.tableOptions.currentPage"
                    :total-rows="inputVal.tableOptions.totalRows"
                    :per-page="inputVal.tableOptions.perPage"
                    @change="pageChanged"
                    class="mr-2"
                ></b-pagination>

                <b-form-input
                    @change="perPageUpdated"
                    :value="inputVal.tableOptions.perPage"
                    class="mb-3 mr-2"
                    style="width: 60px"
                ></b-form-input>

                <b-form-select
                    id="per-page-select"
                    v-model="inputVal.tableOptions.perPage"
                    :options="inputVal.tableOptions.pageOptions"
                    class="mb-3"
                    @change="perPageUpdated"
                ></b-form-select>
            </template>

            <b-button
                @click="refresh"
                variant="info"
                class="ml-2 mb-3"
                :title="this.$t('common.title.actualize')"
            >
                <font-awesome-icon icon="sync"/>
            </b-button>

            <b-dropdown style="z-index: 100" right variant="primary" :text="$t('common.label.actions')" class="ml-2 mb-3">
                <template v-if="actionsList">
                    <b-dropdown-item variant="primary" v-for="(action,index) in actionsList"
                                     :key="index"
                                     @click="action.click"
                                     v-if="!action.hide"
                                     :disabled="action.disabled"
                    >
                        <font-awesome-icon v-if="action.icon" class="mr-2" :icon="action.icon"/>
                        <span v-html="action.title"></span>
                    </b-dropdown-item>
                </template>

                <b-dropdown-item variant="info"
                                 @click="$root.$children[0].openModal('columns-new-modal', {table: table, tableFormat:tableFormat,tableUniqKey:tableUniqKey}, refresh, {width:'800px'})"
                >
                    <font-awesome-icon icon="cogs" class="mr-2"/>
                    <span v-html="this.$t('common.title.config')"></span>
                </b-dropdown-item>

                <b-dropdown-item variant="warning"
                                 @click="$root.$children[0].openModal('export-modal', {
                    type: table,
                    tableOptions: inputVal.tableOptions,
                    tableFormat: tableFormat,
                    filter: filter,
                    additionalOptions: additionalOptions,
                    selected: selected,
                }, refresh, {width: '800px'})"
                >
                    <font-awesome-icon icon="upload" class="mr-2"/>
                    <span v-html="this.$t('common.title.export')"></span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    name: 'table-pagination',
    props: [
        'value',
        'refresh',
        'table',
        'tableFormat',
        'filter',
        'additionalOptions',
        'selected',
        'disablePagination',
        'actionsList',
        'tableUniqKey',
    ],
    methods: {
        filterById(id) {
            this.$set(this.inputVal.filter, '_filter_id', id)
            this.$set(this.value, 'firstLoaded', true)
            this.$set(this.value, 'useSavedFilters', true)
        },
        pageChanged() {
            if (this.inputVal.saveFilters) {
                this.$set(this.inputVal.filter, 'filterChanged', true)
            }
        },
        perPageUpdated(value) {
            if (Object.keys(this.inputVal.filter).length == 0 &&
                typeof this.inputVal.params.filter != "undefined" &&
                Object.keys(this.inputVal.params.filter).length != 0
            ) {
                this.inputVal.filter = this.inputVal.params.filter
            }

            if (this.inputVal.saveFilters) {
                this.$set(this.inputVal.filter, 'filterChanged', true)
            }
            this.inputVal.tableOptions.perPage = value
        },
        deleteSavedFilter(id) {
            axios.delete(window.apiUrl + '/save-table-filter/'+id, {'skip_loading': true})
                .then(result => {
                    this.refresh()
                }, () => {
                    this.loading = false
                })
        },
        updateSavedFilter(id) {
            let params = {
                id: id,
                table: this.table,
                tableFormat: this.tableFormat,
                tableUniqKey: this.tableUniqKey,
                tableOptions: this.value.tableOptions,
                excludeBaseParams: this.value.ctxBaseParams,
                excludeAdditionalParams: this.value.ctxAdditionalParams,
            }
            let filters = this.value.filter

            Object.keys(this.value.ctxBaseParams).forEach(key => {
                params[key] = this.value.ctxBaseParams[key]
            })

            Object.keys(this.value.ctxAdditionalParams).forEach(key => {
                filters[key] = this.value.ctxAdditionalParams[key]
            })

            params['filters'] = filters

            axios.post(window.apiUrl + '/save-table-filter', params, {'skip_loading': true})
                .then(result => {
                    this.refresh()
                }, () => {
                    this.loading = false
                })
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        showingFrom: function () {
            let start = this.inputVal.tableOptions.currentPage * this.inputVal.tableOptions.perPage - this.inputVal.tableOptions.perPage
            return start < 1 ? (this.inputVal.tableOptions.totalRows > 0 ? 1 : 0) : start
        },
        showingTill: function () {
            let end = this.inputVal.tableOptions.currentPage * this.inputVal.tableOptions.perPage
            return end > this.inputVal.tableOptions.totalRows ? this.inputVal.tableOptions.totalRows : end
        },
    },
}
</script>

<style>
.pagination-info {
    float: right;
    color: #768192;
}
</style>